import { useEffect, useState } from "react";
import { handleAdditionalSetting, handleCashReportSetting, handleSettingData } from "../../services/userProfile";
import { showToastMessage } from "../../helpers/toastr";
import { discountValidate } from "../../helpers/discountValidate";

const CashReportSetting = ({ selectGlobalOrg }) => {

    const [data, setData] = useState([]);
    const [error, setErrors] = useState({});

    const [formData, setFormData] = useState({
        orgId: '',
        cashReportPercentage: '', // New field for cash report percentage
    });

    useEffect(() => {
        fetchData();
    }, [selectGlobalOrg]);

    useEffect(() => {
        if (data != null) {
            setFormData({
                orgId: data.org_id,
                cashReportPercentage: data.cash_report_percentage ?? '0', // Set default percentage value
            });
        } else {
            const newOrgId = document.getElementById('orgId').value;
            setFormData({
                orgId: newOrgId,
                cashReportPercentage: null, // Default percentage value
            });
        }
    }, [selectGlobalOrg, data]);

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.cashReportPercentage) {
            newErrors.cashReportPercentage = "Enter a valid percentage .";
            isValid = false;
        } else if (
            isNaN(formData.cashReportPercentage) ||
            formData.cashReportPercentage < 0 ||
            formData.cashReportPercentage > 100
        ) {
            newErrors.cashReportPercentage = "Enter a valid percentage between 0 and 100.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const fetchData = async () => {
        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        try {
            const result = await handleSettingData(initialOrgIds);

            if (result?.data) {
                setData(result?.data?.data);
            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response?.data.message);
        }
    };

    const handleSave = async () => {
        if (validateForm()) {
            try {
                console.log(formData);

                const result = await handleCashReportSetting(formData);

                if (result.data) {
                    showToastMessage('success', result.data.message);
                }
            } catch (error) {
                if (error.response.status === 404) {
                    const errors = error.response.data.message;
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                            errors[key].forEach((message) => {
                                showToastMessage('error', message);
                            });
                        });
                    }
                } else {
                    showToastMessage('error', error.response.data.message);
                }
            }
        }
    };

    return (
        <>
            <form className="mx-auto form mt-4">
                <div className="card p-4">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="form-group">
                                <label htmlFor="cashReportPercentage" className="form-label">Cash Report (%):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cashReportPercentage"
                                    name="cashReportPercentage"
                                    value={formData.cashReportPercentage}
                                    onChange={(e) => handleInputChange(e)}
                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.]/g, '')}
                                    placeholder="Enter Cash Report Discount Percentage"
                                />

                                {error.cashReportPercentage && (
                                    <div className="text-danger">{error.cashReportPercentage}</div>
                                )}
                            </div>

                        </div>

                        <div className="col-md-12 col-12 text-end">
                            <button type="button"
                                className="button button-primary button-icon-left"
                                onClick={() => handleSave()}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CashReportSetting;
