
// import wrapCustomerModalfn from '../../components/customer/CustomerModal.js'
import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { showToastMessage } from '../../helpers/toastr.js';
import wrapModalfn from '../../components/modal/modal.js';
import { formatAmountSymbol } from '../../helpers/formatAmountSymbol.js';
import Loading from '../../components/loader/Loading.jsx';
import { NavLink } from 'react-router-dom';
import { handleDeleteMembership, handleEditBilling, handleMembershipListing, handleMembershipPDF } from '../../services/membershipService.js';
import { formatDate } from '../../helpers/formatDate.js';
import EditMembershipModal from '../../components/sellMemberShip/EditMembershipModal.jsx';
import HistoryModal from '../../components/customer/HistoryModal.jsx';

import { paymentOption } from '../../helpers/paymentOption.js'
import IframeModal from '../../components/modal/IframeModal.jsx';
import { debounce } from 'lodash';
import SearchFilter from '../../components/pagination/SearchFilter.jsx';
import MembershipInvoice from '../InvoicesPrint/MembershipInvoice.jsx';
import { useSelector } from "react-redux";
import checkPermission from "../../helpers/checkPermission";
import showDeletePopup from '../../helpers/showDeletePopup.js';
import ConfirmationPopupButton from '../../components/ConfirmationPopup/ConfirmationPopupButton.jsx';
import { handleGetOtp } from '../../services/billingService.js';
import showOtpVerficationPopup from '../../helpers/showOtpVerficationPopup.js';
import { handleOtpVerfication } from '../../services/billingService.js';
import { conditionalRowStyles } from '../../helpers/conditionalRowStyles.js';


const MembershipListing = ({ selectGlobalOrg, filterData }) => {
    const userData = useSelector((state) => state.userData);
    const [pdfData, setPdfData] = useState([]);
    const [numPages, setNumPages] = useState(1);
    const [popupID, setPopupID] = useState('');
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]); // Use state to store the data

    const [EditBilling, setEditBilling] = useState(null);

    const { customStyles, openModal, closeModal, modalIsOpen, setIsOpen, type } = wrapModalfn();
    const [iframeModalIsOpen, iframeSetIsOpen] = useState(false);
    const [modalIsOpenHis, setIsOpenHis] = useState(false);
    const [hisData, setHisData] = useState([]);



    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;

    const handlePageChange = page => {
        fetchData(filterData, page, perPage, searchValue);
        setCurrentPage(page);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }

    function openIframeModal(type) {
        iframeSetIsOpen(true);
    }

    function closeIframeModal() {
        iframeSetIsOpen(false);
    }

    const columns = [
        {
            name: 'Bill No',
            selector: row => row?.bill_no,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.get_customer_info?.name,
            sortable: true,
            sortFunction: (a, b) => a.get_customer_info?.name.localeCompare(b.get_customer_info?.name),
        },
        {
            name: 'Mobile Number',
            selector: row => row.get_customer_info?.phone_number,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.created_at ? formatDate(row.created_at) : '',
            sortable: true,
        },
        {
            name: 'Plan Expired',
            selector: row => row.membership_expired ? formatDate(row.membership_expired) : '',
            sortable: true,
        },
        {
            name: 'Payment Mode',
            selector: row => paymentOption(row.payment_mode),
            sortable: true,
            // selector: row => row && row.out_standing !== null ? row.out_standing : '0.00',
        },
        {
            name: 'OutStanding',
            selector: row => row?.out_standing ? formatAmountSymbol(row?.out_standing) : formatAmountSymbol(0),
            sortable: true,
            sortFunction: (a, b) => parseFloat(a.out_standing) - parseFloat(b.out_standing),
            cell: row => formatAmountSymbol(row?.out_standing || '0.00'),
            // selector: row => row && row.out_standing !== null ? row.out_standing : '0.00',
        },
        {
            name: 'Grand Total',
            selector: row => row?.round_off_total ? formatAmountSymbol(row?.round_off_total) : formatAmountSymbol(0),
            sortable: true,
            sortFunction: (a, b) => parseFloat(a.round_off_total) - parseFloat(b.round_off_total),
            cell: row => formatAmountSymbol(row?.round_off_total || '0.00'),
            // selector: row => row && row.out_standing !== null ? row.out_standing : '0.00',
        },
    ];
    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.deleted_at !== null && row?.has_latest_delete_request?.length > 0 ? row.has_latest_delete_request[0].reason : '',
            sortable: true,
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update memberships') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is deleted, you can not edit it.');
                            } else {
                                handleEdit(row.id, 'edit');
                            }
                        }}
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}
                {checkPermission(userData, 'Delete memberships') && (
                    <ConfirmationPopupButton
                        handleDelete={() => handleDelete(row.uuid)}
                    />
                )}
                <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleDownload(row.uuid)}><i class="mdi mdi-eye menu-icon bodytext-24"></i></button>

            </div>
        ),
    });

    const handleEdit = async (uuid, type) => {



        try {
            const result = await handleEditBilling(uuid);

            if (result.data) {
                //    setSelectedCustomer(result.data?.data)
                openModal(type)
                setEditBilling(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    const handleDownload = async (uuid, pdfType) => {

        console.log("uuid", uuid);

        try {
            const result = await handleMembershipPDF(uuid);

            openIframeModal();

            // const reader = new FileReader();
            // reader.readAsDataURL(result.data);
            // reader.onloadend = () => {
            //   const base64data = reader.result;
            //   setPdf(base64data);
            // };const pdf = await result.data.arrayBuffer();
            // const pdfData = new Uint8Array(pdf);
            // const pdfDoc = await pdfjsLib.getDocument({ data: pdfData }).promise;
            setPdfData(result?.data?.data);

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response?.data.message);
        }

    }

    const handleDelete = async (uuid) => {
        const reason = await showDeletePopup();

        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteMembership(uuid, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(filterData, currentPage, perPage);
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const requestType = 'delete';
            const result = await handleGetOtp(uuid, reason, requestType);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(uuid, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteMembership(uuid, reason);
                            fetchData(filterData, currentPage, perPage);
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message)
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Edit operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }
        }



    };

    const customSort = (rows, selector, direction) => {
        return orderBy(rows, selector, direction);
    };

    const handleHistory = async (data) => {
        setHisData(data);
        setIsOpenHis(true);

    }

    const closeModalHis = async () => {

        setIsOpenHis(false);
        setHisData([]);

    }

    useEffect(() => {

        console.log(filterData, 'filterDatafilterDatafilterData');
        if (filterData != undefined) {
            const parsedFilterData = JSON.parse(filterData);
            if (parsedFilterData.length === 0) {
                return;
            }
        }



        const debouncedFetchData = debounce(fetchData, 300);

        // fetchData(1, perPage, searchValue);

        const fetchDataOnChange = () => {
            debouncedFetchData(filterData, 1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(filterData, 1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue, filterData]);

    // useEffect(() => {

    //         fetchData();

    // }, [selectGlobalOrg])

    const fetchData = async (filterData, page, per_page, searchText) => {

        // if(filterData == undefined){
        //     // const parsedFilterData = JSON.parse(filterData);
        //     // if (parsedFilterData.length === 0) { 
        //         return; 
        //     // }
        // }

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleMembershipListing(initialOrgIds, filterData, page, per_page, searchText);
            if (result.data) {
                setData(result.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        } finally {
            setLoading(false)
        }

    }

    const iframeHeight = `${numPages * 700}px`;

    return (
        <>

            {/* <ConfirmationPopup
                handleDelete={handleDelete}
                popupID={popupID}
                setPopupID={setPopupID}
            /> */}

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div class="col">
                                <h2 class="card-title heading-h3 mb-0">Membership Listing</h2>
                            </div>
                            <div class="col-auto">
                                {checkPermission(userData, 'Create memberships') && (
                                    <NavLink to="/sell-membership" className="btn button-primary btn-icon-left modalButtonCommon">
                                        <span class="icon">
                                            <i class="fa fa-plus-circle"></i> 
                                        </span>
                                        New Membership
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        <HistoryModal
                            modalIsOpen={modalIsOpenHis}
                            closeModal={closeModalHis}
                            customStyles={customStyles}
                            hisData={hisData}

                        />

                        <EditMembershipModal
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            closeModal={closeModal}
                            customStyles={customStyles}
                            EditBilling={EditBilling}
                            fetchData={() => fetchData(filterData, currentPage, perPage)}
                            type={type}
                        />
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <DataTable
                                        columns={columns}
                                        data={data?.data}
                                        pagination
                                        paginationPerPage={30}
                                        paginationServer
                                        paginationTotalRows={data?.total}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        subHeader
                                        subHeaderComponent={<SearchFilter
                                            setSearchValue={setSearchValue}
                                            searchValue={searchValue}
                                        />}
                                        paginationComponentOptions={{
                                            rangeSeparatorText: 'of',
                                            noRowsPerPage: true,
                                        }}
                                        {...(hasAdminRole && { conditionalRowStyles })}
                                    />
                                </>
                            )}
                    </div>
                </div>
                {/* {console.log(pdfData,'pdfDatapdfDatapdfData')} */}
                <IframeModal
                    modalIsOpen={iframeModalIsOpen}
                    setIsOpen={iframeSetIsOpen}
                    closeModal={closeIframeModal}
                    customStyles={customStyles}
                    layout={<MembershipInvoice data={pdfData} />}
                />
                {/* <IframeModal 
                modalIsOpen={iframeModalIsOpen}
                setIsOpen={iframeSetIsOpen}
                closeModal={closeIframeModal}
                customStyles={customStyles}
                layout = {<MembershipInvoice data={pdfData} />}
                // pdf = {pdf}
                // iframeHeight={iframeHeight}
                
                /> */}
        </>

    )

}

export default MembershipListing;