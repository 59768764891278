import axiosInstance from "./axiosConfig";

const handleReportData = async (formData) => {
  try {
    const response = await axiosInstance.post('get-report-data', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleSaleReportData = async (formData) => {
  try {
    const response = await axiosInstance.post('get-sales-report', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleStaffReportData = async (formData) => {
  try {
    const response = await axiosInstance.post('get-staff-report', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export {
    handleReportData,
    handleSaleReportData,
    handleStaffReportData
};