// TableComponent.jsx
import React from 'react';

const ReportTable = ({ tableData, type }) => {
    let rows = [];
    if (type === "paymentMode") {
        // Calculate total sales for each payment mode
        rows = tableData.map(row => ({
            type: row.paymentMode,
            totalSales: row.serviceSales + row.membershipSales + row.productSales
        }));
    } else if (type === "serviceCategory") {
        // Use the gender and sales data as is
        rows = tableData.map(row => ({
            type: row.gender,
            sales: row.sales
        }));
    } else if (type === "overview") {
        // Use the overviewType and amount data as is
        rows = tableData.map(row => ({
            type: row.overviewType,
            amount: row.amount
        }));
    }
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Sales Amount</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>{row.type}</td>
                        <td>{row.totalSales || row.sales || row.amount}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ReportTable;
