// import axios from 'axios';
// const baseUrl = process.env.REACT_APP_BASE_URL;

import axiosInstance from "./axiosConfig";

const handleUserProfile = async () => {

  try {
    const response = await axiosInstance.get(`profile`);

    return response; // Assuming you want to return the data from the response
  } catch (error) {
    console.log(error);
    localStorageData.remove('token')
    window.location.href = "/";
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleWhatsAppIntegration = async (formData) => {
  try {
    const response = await axiosInstance.post('save-whatsapp-integration', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// get-site-settings

const handleSettingData = async (formData) => {
  try {
    const response = await axiosInstance.get(`get-site-settings?orgId=${formData}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleOrganisationData = async (formData) => {
  try {
    const response = await axiosInstance.get(`get-org-data?orgId=${formData}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleOrganisation = async (formData) => {
  try {
    const response = await axiosInstance.post('save-org-data', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleAdditionalSetting = async (formData) => {
  try {
    const response = await axiosInstance.post('save-additional-data', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleCashReportSetting = async (formData) => {
  try {
    const response = await axiosInstance.post('save-cash-report-limit', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }

}

export {
  handleUserProfile,
  handleWhatsAppIntegration,
  handleSettingData,
  handleOrganisationData,
  handleOrganisation,
  handleAdditionalSetting,
  handleCashReportSetting
};

