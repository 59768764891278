import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import { formatDate } from '../../../helpers/formatDate';
import { handleSaleReportData } from '../../../services/reportService';
import { showToastMessage } from '../../../helpers/toastr';
import * as XLSX from 'xlsx';
import { useReactToPrint } from 'react-to-print';
import { mdiFileDownload, mdiFileExcel, mdiFileExcelBox } from '@mdi/js';  // Excel icon
import { mdiFilePdfBox } from '@mdi/js';
import Icon from '@mdi/react';
import { handleSettingData } from '../../../services/userProfile';
import { paymentOption } from '../../../helpers/paymentOption';

const SalesReport = ({ selectGlobalOrg }) => {
  const userData = useSelector((state) => state.userData);

  const [selectedDay, setSelectedDay] = useState('');
  const [selectedWeek, setSelectedWeek] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selection, setSelection] = useState('Today');
  const [dateInputValue, setDateInputValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
    clearDateInputs(); // Clear date inputs when selection changes
  };

  const handleDateInputChange = (e) => {
    setDateInputValue(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const clearDateInputs = () => {
    setDateInputValue('');
    setStartDate('');
    setEndDate('');
  };

  useEffect(() => {
    fetchData();
  }, [selectGlobalOrg, selection, dateInputValue, startDate, endDate]);

  const fetchData = async () => {
    const orgID = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
    let filterType = selection;
    let filterPeriod = '';

    switch (selection) {
      case 'Date':
        filterPeriod = dateInputValue;
        break;
      case 'CustomDate':
        filterPeriod = { startDate, endDate };
        break;
      default:
        break;
    }

    const formData = {
      orgID,
      filterType,
      filterPeriod,
    };


    try {
      const result = await handleSaleReportData(formData);
      if (result.data) {
        const reportData = result.data.data;
        setData(reportData);
        calculateTotal(reportData); // Call function to calculate total
      }
    } catch (error) {
      console.error('Error fetching sales report data:', error);
      showToastMessage('error', error.response?.data?.message || 'Error fetching data');
    }
  };



  const calculateTotal = (reportData) => {
    const total = reportData.reduce((acc, row) => {
      const amount = row?.calculated_percentage ?? row?.round_off_total ?? 0;
      return acc + amount;
    }, 0);
    setTotalAmount(total);
  };


  const columns = [
    {
      name: 'Bill No',
      selector: (row) => row.bill_no ?? 'Null',
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: row => row.get_customer_info?.name,
      sortable: true,
    },
    {
      name: 'Mobile Number',
      selector: row => row.get_customer_info?.phone_number,
      sortable: true,
    },
    {
      name: 'Service Type',
      selector: row => row.get_service_billings?.[0]?.payment_method === 'Package'
        ? 'Package Base'
        : 'Service Base' ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Payment Method',
      selector: row => paymentOption(row.payment_mode),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row.discount ?? 0,
      sortable: true,
    },
    {
      name: 'Total',
      selector: (row) => {
        return row?.calculated_percentage ?? row.round_off_total ?? 0;
      },
      sortable: true,
    },




    {
      name: 'Created',
      selector: row => row.created_at ? formatDate(row.created_at) : '',
      sortable: true,
    },
  ];
  useEffect(() => {
    fetchData();
  }, [selectGlobalOrg, selectedDay, selectedWeek, selectedDate, selectedMonth]);




  const exportToExcel = () => {
    const visibleColumns = columns.filter(col => col.selector);
    const filteredData = data.map(row => {
      const filteredRow = {};
      visibleColumns.forEach(col => {
        filteredRow[col.name] = col.selector(row);
      });
      return filteredRow;
    });

    // Add total row
    const totalRow = {
      'Bill No': 'Total',
      'Customer Name': '',
      'Mobile Number': '',
      'Service Type': '',
      'Payment Method': '',
      'Discount': '',
      'Total': calculatePageTotal(data),
      'Created': '',
    };
    filteredData.push(totalRow);

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sales Report');
    XLSX.writeFile(wb, 'sales_report.xlsx');
  };


  const handlePrint = useReactToPrint({
    content: () => {
      const printTable = document.getElementById('print-table');
      const footerRow = document.createElement('div');
      footerRow.textContent = `Total for Page: ${calculatePageTotal(data)}`;
      footerRow.style = 'text-align: right; font-weight: bold; margin-top: 10px;';
      printTable.appendChild(footerRow);
      return printTable;
    },
    documentTitle: 'Sales Report',
    onAfterPrint: () => alert('PDF Exported successfully'),
  });

  const calculatePageTotal = (rows) => {
    return rows.reduce((acc, row) => acc + (row?.calculated_percentage ?? row.round_off_total ?? 0), 0);
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row col-padding">
          <div className="col-12">
            <div className="card card-filter">
              <div className="card-filter-wrap">
                <h4 className="">Sales Report</h4>

                <div className="card-filter-input">
                  {data.length > 0 && (
                    <>
                      <button
                        onClick={exportToExcel}
                        className="btn btn-block btn-h-auto btn-gradient-danger btn-lg font-weight-medium auth-form-btn"
                      >
                        Export Excel
                      </button>
                      <button
                        onClick={handlePrint}
                        className="btn btn-block btn-h-auto btn-gradient-danger btn-lg font-weight-medium auth-form-btn"
                      >
                        Download PDF
                      </button>
                      <span className="btn btn-block btn-plane btn-h-auto">
                        <strong>Total: </strong>{totalAmount}
                      </span>
                    </>
                  )}


                  <div className="input-date">
                    <select
                      className="form-select"
                      value={selection}
                      onChange={handleSelectionChange}
                    >
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Date">Date</option>
                      <option value="Last-7-Days">Last 7 Days</option>
                      <option value="Last-14-Days">Last 14 Days</option>
                      <option value="Last-30-Days">Last 30 Days</option>
                      <option value="Overall">Overall</option>
                      <option value="CustomDate">Custom Date</option>
                    </select>
                  </div>
                  {selection === 'CustomDate' ? (
                    <>
                      <div className="input-date">
                        <input
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          className="form-control"
                        />
                      </div>

                      <div className="input-date">
                        <input
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          className="form-control"
                        />
                      </div>
                    </>

                  ) : selection === 'Date' ? (
                    <div className="input-date">
                      <input
                        type="date"
                        value={dateInputValue}
                        onChange={handleDateInputChange}
                        className="form-control"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">


            <div id="print-table">
              <DataTable
                columns={columns}
                data={data}
                pagination
                responsive
                striped
                customStyles={{
                  rows: {
                    style: {
                      borderBottom: '2px solid #ddd', // Optional: Styling for the row separator
                    },
                  },
                  headCells: {
                    style: {
                      backgroundColor: '#f8f8f8', // Optional: Styling for header
                    },
                  },
                  footer: {
                    style: {
                      backgroundColor: '#f8f8f8', // Optional footer style
                      fontWeight: 'bold',
                    },
                  },
                }}
                noHeader={false}
                highlightOnHover
                footer={
                  <div style={{ textAlign: 'right', padding: '10px', fontWeight: 'bold' }}>
                    Total for Page: {calculatePageTotal(data)}
                  </div>
                }
              />

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default SalesReport;
