import React, { useEffect, useState } from 'react';
import Select from 'react-select';


export const SelectStaffService = ({ index, services, staffData, removeRow, value , handleFieldChange , errors}) => {

    return (
        <div className="" id="item_table">
        <div className="row" id={index} key={index}>
        <div className="col-sm col-md col-lg col-xl">
            <div className="form-group">
                <label>Service</label>
                <Select
                    name="serviceId"
                    options={services}
                    placeholder={'Select Service'}
                    isSearchable={true}
                    classNamePrefix="form"
                    value={services.reduce((acc, group) => {
                        if (group && group.options) {
                            const selectedOption = group.options.find(option => option.value === value.serviceId);
                            if (selectedOption) {
                                acc = selectedOption;
                            }
                        }
                        return acc;
                    }, null)}
                    onChange={(option) => {
                        // handleServicecategory(option, index);
                        handleFieldChange('serviceId', option.value, index)
                    }}
                />
                 {/* {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )} */}
            </div>
        </div>
        
        <div className="col-sm col-md col-lg col-xl">
            <div className="form-group">
            <label>Staff</label>
                <Select
                    name="staffId"
                    value={staffData.find(option => option.value === value.staffId)}
                    isSearchable={true}
                    options={staffData}
                    classNamePrefix="form"
                    placeholder={'Select Staff'}
                    onChange={option => handleFieldChange('staffId', option.value, index)}
                />
            </div>
        </div>
        {/* <td>
                 
            <div className="">
                <Select
                   
                    value={
                        staffData.filter(
                            option => 
                            value?.coStaffId.includes(option.value)
                            )
                        }
                    name="coStaffId"
                    isSearchable={true}
                    options={staffData}
                    isMulti={true}
                    placeholder={'Select Co-Staff'}
                    onChange={selectedOptions => handleFieldChange('coStaffId', selectedOptions.map(option => option.value), index)}

                />

            </div>
        </td>
        <td className='td-sm'>
            <div className="">
                <input
                    name="quantity"
                    type="number"
                    value={value.quantity}
                    className="required form-control calcEvent service_qty input-sm"
                    step="any"
                    min="1"
                    onChange={e => handleFieldChange('quantity', e.target.value, index)}
                />
            </div>
        </td>
        <td className='td-md'>
            <div className="">
                <input
                    name="price"
                    type="number"
                    value={value.price}
                    className="required form-control calcEvent service_price input-sm"
                    step="any"
                    min="0"
                    onChange={e => handleFieldChange('price', e.target.value, index)}
                />
            </div>
        </td>
        <td className='td-xs'>
            <div className="percentage">
                <input
                    name="tax"
                    type="hidden"
                    value={value.tax || 18}
                    onChange={e => handleFieldChange('tax', e.target.value, index)}
                    className="service_gst form-control"
                    placeholder="GST(%)"
                />
                <span className="service_gst_txt">18%</span>
            </div>
        </td>
        <td className="td-md text-right">
        <div className="percentage">
            <input
                name="total"
                type="hidden"
                value={value.total}
                className="service_total form-control"
            />
            <span className="service_total_txt">{value.total}</span>
            </div>
        </td> */}
        <div className="col-12 col-sm-auto col-delete">
            <button type="button" className="button button-primary button-icon" onClick={() => removeRow(index)}>
                <i class="mdi mdi-delete-empty menu-icon bodytext-24"></i>
            </button>
        </div>
    </div>
    </div>
    );
};
