import axiosInstance from "./axiosConfig";

const handlePackage = async (formData, reason) => {
  try {
    const response = await axiosInstance.post(`save-package?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handlePackageListing = async (formData, page, per_page, searchText) => {
  try {
    const response = await axiosInstance.get(`get-package-listing?orgId=${formData}`, {
      params: {
        page: page,
        per_page: per_page,
        searchText: searchText,
      },
    });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



const handleEditPackage = async (id) => {
  try {
    const response = await axiosInstance.get(`edit-package?id=${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeletePackage = async (id, reason) => {
  try {
    const response = await axiosInstance.get(`delete-package?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleSavePackage = async (orgId, formData) => {
  try {
    const response = await axiosInstance.post(`add-advance-package?orgId=${orgId}`, formData);
    return response;
  } catch (error) {
    throw error;
  }

}

const handleAdvancePackage = async (orgId, page, per_page, searchText) => {
  try {


    const response = await axiosInstance.get(`get-advance-package-listing`, {
      params: {
        orgId: orgId, 
        page: page,
        per_page: per_page,
        searchText: searchText,
      },
    });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


const handleDeleteAdvancePackage = async (id, reason) => {
  try {
    const response = await axiosInstance.get(`delete-advance-package?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditAdvanceBilling = async (org_id, id) => {
  try {
    const response = await axiosInstance.get(`edit-advance-package?id=${id}&org_id=${org_id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleAdvancePackagePDF = async (org_id,id) => {
  try {
    const response = await axiosInstance.get(`download-advance-package-pdf?org_id=${org_id}&id=${id}`
    // , {
    //   responseType: 'blob',
    //   headers: {
    //     Accept: 'application/pdf',
    //     'Content-Type': 'application/pdf',
    //   },
    // }
    );
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
export {
  handlePackage,
  handlePackageListing,
  handleEditPackage,
  handleDeletePackage,
  handleSavePackage,
  handleAdvancePackage,
  handleDeleteAdvancePackage,
  handleEditAdvanceBilling,
  handleAdvancePackagePDF
};





