import React from "react";

const CustomerServicesTable = ({ data, onServiceSelect }) => {
    const { advance_packages } = data;

    const handleUsedQuantityChange = (service, value) => {
        const quantityUsed = Math.min(Math.max(parseInt(value, 10) || 0, 0), service.quantity);
        onServiceSelect((prevSelected) => {
            const existingService = prevSelected.find((selected) => selected.id === service.id);
            if (existingService) {
                return prevSelected.map((selected) =>
                    selected.id === service.id
                        ? { ...selected }
                        : selected
                );
            } else {
                return [...prevSelected, { ...service, }];
            }
        });
    };

    const handleCheckboxChange = (service, isChecked) => {
        onServiceSelect((prevSelected) => {
            if (isChecked) {
                // Add the service with an initial used quantity of 0 if not already selected
                if (!prevSelected.some((selected) => selected.id === service.id)) {
                    return [...prevSelected, { ...service }];
                }
            } else {
                // Remove the service from the selection
                return prevSelected.filter((selected) => selected.id !== service.id);
            }
            return prevSelected;
        });
    };

    return (
        <div>
            {advance_packages && advance_packages.length > 0 ? (
                advance_packages.map((pkg) => (
                    <div key={pkg.id}>
                        <h2 className="card-title heading-h3 mb-0">{pkg.package_name}</h2>
                        <table
                            className="table table-package"
                            style={{ width: "50%", borderCollapse: "collapse" }}
                        >
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Service Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pkg.services.map((service) => (
                                    <tr key={service.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleCheckboxChange(service, e.target.checked)
                                                }
                                            />
                                        </td>
                                        <td>{service.service.name}-({service.quantity})</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))
            ) : (
                <p>No packages found for this customer.</p>
            )}
        </div>
    );
};

export default CustomerServicesTable;
