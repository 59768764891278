import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import ReportTable from '../../../components/ReportTable/ReportTable';
import { handleReportData } from '../../../services/reportService';
import {FilterComponent} from '../../../components/FilterComponent';
import { showToastMessage } from '../../../helpers/toastr';

// Register the necessary components with Chart.js
Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const OverviewReport = ({ selectGlobalOrg }) => {
    const userData = useSelector((state) => state.userData);
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedWeek, setSelectedWeek] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [forceUpdate, setForceUpdate] = useState(false);

    const [selection, setSelection] = useState('Today');

    const [dateInputValue, setDateInputValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState([]);

    const hasAdminRole = userData?.role?.role === 'admin';
    const hasCoOwnerRole = userData?.role?.role === 'co-owner';

    useEffect(() => {

        if ((selection === 'Date' && !dateInputValue) || (selection === 'CustomDate' && (!startDate || !endDate))) {
            return;
        }
        fetchData();

    }, [selectGlobalOrg, selection, dateInputValue, startDate, endDate])

    const fetchData = async () => {
        const orgID = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        const formData = {
            orgID,
            filterType: selection,
            filterPeriod: dateInputValue,
            startDate: startDate,
            endDate: endDate
        }

        setFormData(formData);

        try {
            const result = await handleReportData(formData);
            if (result.data) {
                setData(result.data.data);
            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        }
    };
    const handleSelectionChange = (e) => {
        setSelection(e.target.value);
        if ((e.target.value === 'Month' || e.target.value === 'Today' || e.target.value === 'Overall' || e.target.value === 'Year') ||
            (e.target.value === 'Date' && dateInputValue) ||
            (e.target.value === 'CustomDate' && startDate && endDate)) {
            setSelection(e.target.value);
        }
    };

    const handleDateInputChange = (e) => {
        if (selection === 'Date' && e.target.value) {
            setDateInputValue(e.target.value);
        }
    };

    const handleStartDateChange = (e) => {
        if (selection === 'CustomDate' && e.target.value) {
            setStartDate(e.target.value);
        }
    };

    const handleEndDateChange = (e) => {
        if (selection === 'CustomDate' && e.target.value) {
            setEndDate(e.target.value);
        }
    };

    const handleGenerateReport = () => {
        fetchData();
        console.log('Generating report for:', { selectedDay, selectedMonth, selectedDate });
    };



    const paymentTableData = data.paymentModes ? Object.keys(data.paymentModes).map((key, index) => ({
        key: `payment-${index}`,
        paymentMode: key,
        ...data.paymentModes[key]
    })) : [];

    const serviceCategoryTableData = data.serviceSalesByGender ? Object.keys(data.serviceSalesByGender).map((key, index) => ({
        key: `service-${index}`,
        gender: key,
        sales: data.serviceSalesByGender[key]
    })) : [];

    const overviewTableData = data.overview ? Object.keys(data.overview).map((key, index) => ({
        key: `overview-${index}`,
        overviewType: key,
        amount: data.overview[key]
    })) : [];
    const bar1Data = {
        labels: paymentTableData.map(item => item.paymentMode),
        datasets: [
            {
                label: "Total Sales",
                data: paymentTableData.map(item => item.serviceSales + item.membershipSales + item.productSales), // Sum of sales for each payment mode
                backgroundColor: ["green", "red", "yellow", "blue"],
                borderColor: ["green", "red", "yellow", "blue"],
                borderWidth: 0.5,
            },
        ],
    };

    const bar2Data = {
        labels: serviceCategoryTableData.map(item => item.gender),
        datasets: [
            {
                label: "Total Sales",
                data: serviceCategoryTableData.map(item => item.sales),
                backgroundColor: ["green", "red"],
                borderColor: ["green", "red"],
                borderWidth: 0.5,
            },
        ],
    };

    const bar3Data = {
        labels: overviewTableData.map(item => item.overviewType),
        datasets: [
            {
                label: "Amount",
                data: overviewTableData.map(item => item.amount),
                backgroundColor: ["green", "red", "yellow", "purple", "blue"],
                borderColor: ["green", "red", "yellow", "purple", "blue"],
                borderWidth: 0.5,
            },
        ],
    };


    const barOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                labels: {
                    fontSize: 10,
                },
            },
        },
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div class="row col-padding">
                    <div class="col-12">
                        <div class="card card-filter">
                            <div class="card-filter-wrap">
                                <h4 class="">Overview</h4>
                                <div class="card-filter-input">
                                    <div className="input-date">
                                        <select
                                            className="form-select"
                                            value={selection}
                                            onChange={handleSelectionChange}
                                        >
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">Yesterday</option>
                                            <option value="Date">Date</option>
                                            <option value="Last-7-Days">Last 7 Days</option>
                                            <option value="Last-14-Days">Last 14 Days</option>
                                            <option value="Last-30-Days">Last 30 Days</option>
                                            <option value="Overall">Overall</option>
                                            <option value="CustomDate">Custom Date</option>
                                        </select>
                                    </div>
                                    {selection === 'CustomDate' ? (
                                        <>
                                            <div className="input-date">
                                                <input
                                                    type="date"
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    className="form-control"
                                                />
                                            </div>

                                            <div className="input-date">
                                                <input
                                                    type="date"
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </>

                                    ) : selection === 'Date' ? (
                                        <div className="input-date">
                                            <input
                                                type="date"
                                                value={dateInputValue}
                                                onChange={handleDateInputChange}
                                                className="form-control"
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body mt-3 bg-white rounded">
                    <div className="card-body">
                        <div className="row d-flex xxl">
                            <div className="chart-small col">
                                <Bar data={bar1Data} options={barOptions} />
                            </div>
                            <div className="chart-small col gap-2">
                                <div className="">
                                    <h5 className="text-center text-light py-2 bg-secondary">Payment Mode</h5>
                                </div>
                                <ReportTable tableData={paymentTableData} type="paymentMode" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body mt-4 bg-white rounded">
                    <div className="card-body">
                        <div className="row  max">
                            <div className="chart-small col">
                                <Bar data={bar2Data} options={barOptions} />
                            </div>
                            <div className="chart-small col gap-2">
                                <div className="">
                                    <h5 className="text-center text-light py-2 bg-secondary">Service By Category</h5>
                                </div>
                                <ReportTable tableData={serviceCategoryTableData} type="serviceCategory" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body mt-4 bg-white rounded">
                    <div className="card-body">
                        <div className="row max">
                            <div className="chart-small col">
                                <Bar data={bar3Data} options={barOptions} />
                            </div>
                            <div className="chart-small col gap-2">
                                <div className="">
                                    <h5 className="text-center text-light py-2 bg-secondary">Total Sales</h5>
                                </div>
                                <ReportTable tableData={overviewTableData} type="overview" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverviewReport;
