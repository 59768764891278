import axiosInstance from "./axiosConfig";

const handleLogin = async (formData) => {
  try {
    const response = await axiosInstance.post('login', formData);
    return response;
  } catch (error) {
    // Check if the error has a response (server-side error)
    if (error.response) {
      // If the server responds with an error status
      console.error('Server responded with an error:', error.response.data);
      throw {
        message: error.response.data.message || 'Internal Server Error.',
        status: error.response.status || 500,
      };
    } else if (error.request) {
      // If the request was made but no response was received
      console.error('No response received from server:', error.request);
      throw {
        message: 'Server is unreachable. Please try again later.',
        status: 500,
      };
    } else {
      // Other errors
      console.error('Unexpected error:', error.message);
      throw {
        message: 'An unexpected error occurred.',
        status: 500,
      };
    }
  }
};


const handleForgetPassword = async (forgetPasswordData) => {
  try {
    const response = await axiosInstance.post('forgot-password', forgetPasswordData);
    return response;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

const handleResetPassword = async (formData) => {
  try {
    const response = await axiosInstance.post('reset-email-password', formData);
    return response;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};

export {
  handleLogin,
  handleForgetPassword,
  handleResetPassword,
};