import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker } from 'rsuite';
import { Bar } from 'react-chartjs-2';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import ReportTable from '../../../components/ReportTable/ReportTable';
import DataTable from "react-data-table-component";
import { handleStaffReportData } from '../../../services/reportService';

import FilterComponent from '../../../components/FilterComponent';
import { showToastMessage } from '../../../helpers/toastr';
import checkPermission from '../../../helpers/checkPermission';
import ConfirmationPopupButton from '../../../components/ConfirmationPopup/ConfirmationPopupButton';
const StaffReport = ({ selectGlobalOrg, filterData }) => {
  const userData = useSelector((state) => state.userData);

  const [selectedDay, setSelectedDay] = useState('');
  const [selectedWeek, setSelectedWeek] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selection, setSelection] = useState('Today');
  const [dateInputValue, setDateInputValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);

  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
    clearDateInputs(); // Clear date inputs when selection changes
  };

  const handleDateInputChange = (e) => {
    setDateInputValue(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const clearDateInputs = () => {
    setDateInputValue('');
    setStartDate('');
    setEndDate('');
  };

  useEffect(() => {
    fetchData();
  }, [selectGlobalOrg, selection, dateInputValue, startDate, endDate]);

  const fetchData = async () => {
    const orgID = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
    let filterType = selection;
    let filterPeriod = '';

    switch (selection) {
      case 'Date':
        filterPeriod = dateInputValue;
        break;
      case 'CustomDate':
        filterPeriod = { startDate, endDate };
        break;
      default:
        break;
    }

    const formData = {
      orgID,
      filterType,
      filterPeriod,
    };

    try {
      const result = await handleStaffReportData(formData);
      if (result.data) {
        setData(result.data.data);
      }
    } catch (error) {
      console.error('Error fetching sales report data:', error);
      showToastMessage('error', error.response?.data?.message || 'Error fetching data');
    }
  };
  const columns = [
    {
      name: 'Emp Name',
      selector: row => row.staffName,
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => true,
          style: {
            color: 'red',
          },
        },
      ],
    },
    {
      name: 'Salary',
      selector: row => 15000,
      sortable: true,
    },
    {
      name: 'Total Customers',
      selector: row => row.customerAttendedCount,
      sortable: true,
    },
    {
      name: 'Service Sales',
      selector: row => row.totalServiceSales,
      sortable: true,
    },
    {
      name: 'Target Sales(5x)',
      selector: row => 50000,
      sortable: true,
    },
    {
      name: 'Incentive(3%)',
      selector: row => 0,
      sortable: true,
    },
    {
      name: 'Product Sales',
      selector: row => row.totalProductSales ?? 0,
      sortable: true,
    },
    {
      name: 'Package Sales',
      selector: row => row.totalPackageSales ?? 0,
      sortable: true,
    },
    {
      name: 'Grand Total',
      selector: row => row.totalServiceSales + row.totalPackageSales + row.totalProductSales ?? 0,
      sortable: true,
    },
  ];
  useEffect(() => {
    fetchData();
  }, [selectGlobalOrg, selectedDay, selectedWeek, selectedDate, selectedMonth]);


  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row col-padding">
          <div className="col-12">
            <div className="card card-filter">
              <div className="card-filter-wrap">
                <h4 className="">Staff Report</h4>
                <div className="card-filter-input">
                  <div className="input-date">
                    <select
                      className="form-select"
                      value={selection}
                      onChange={handleSelectionChange}
                    >
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Date">Date</option>
                      <option value="Last-7-Days">Last 7 Days</option>
                      <option value="Last-14-Days">Last 14 Days</option>
                      <option value="Last-30-Days">Last 30 Days</option>
                      <option value="Overall">Overall</option>
                      <option value="CustomDate">Custom Date</option>
                    </select>
                  </div>
                  {selection === 'CustomDate' ? (
                    <>
                      <div className="input-date">
                        <input
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          className="form-control"
                        />
                      </div>

                      <div className="input-date">
                        <input
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          className="form-control"
                        />
                      </div>
                    </>

                  ) : selection === 'Date' ? (
                    <div className="input-date">
                      <input
                        type="date"
                        value={dateInputValue}
                        onChange={handleDateInputChange}
                        className="form-control"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <DataTable
              columns={columns}
              data={data}
              pagination
              responsive
              striped
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default StaffReport;
