import styles from "./styles";
import { paymentOption } from "../../helpers/paymentOption";

const AdvancePackageInvoice = ({ data }) => {
    return (
        <>
            <div className="invoice" style={styles.invoice}>
                <table style={styles.width}>
                    <tr>
                        <td>
                            <table style={styles.width}>
                                <tr>
                                    <td style={styles.textCenter}>
                                        <h1 className="heading" style={styles.heading}>
                                            {data?.get_org_info?.name || "Organization Name"}
                                        </h1>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="borderb" style={{ ...styles.borderb, ...styles.paddingb }}>
                            <table style={styles.width}>
                                <tr>
                                    <td className="info" style={styles.info}>
                                        {data.date && (
                                            <p className="info" style={styles.info}>
                                                Date: {data.date}
                                            </p>
                                        )}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
                            <table style={styles.width}>
                                <tr>
                                    <td>
                                        <table style={styles.width}>
                                            <tr>
                                                <td>
                                                    <p className="billno" style={styles.billno}>Bill To:</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="billno" style={styles.billno}>{data.customer?.name || "Customer Name"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="billno" style={styles.billno}>{data.customer?.phone_number || "Phone Number"}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td className="info" style={styles.info}>
                                        <table>
                                            <tr>
                                                <td>
                                                    <p className="billno" style={styles.billno}>
                                                        Bill No: {data.id}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {data.get_org_info?.gst_no && (
                                                        <div className="billno" style={styles.billno}>
                                                            <span className="billno" style={styles.billno}>GST No: {data.get_org_info.gst_no}</span>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table className="invoice-table" style={styles.width}>
                                <thead className="theadbg" style={styles.theadbg}>
                                    <tr>
                                        <th className="theadth" style={styles.theadth}>Service</th>
                                        <th className="theadth" style={{ ...styles.theadth, ...styles.textCenter }}>Qty</th>
                                        <th className="theadth" style={{ ...styles.theadth, ...styles.textRight }}>Price</th>
                                        <th className="theadth" style={{ ...styles.theadth, ...styles.textRight }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.services?.map((item, index) => (
                                        <tr key={index} className="" style={styles.borderb}>
                                            <td className="theadtd" style={styles.theadtd}>
                                                {item?.service?.name || "Service Name"}
                                            </td>
                                            <td className="theadtd" style={{ ...styles.theadtd, ...styles.textCenter }}>
                                                {item.quantity}
                                            </td>
                                            <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                                                {item.service?.price}
                                            </td>
                                            <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                                                {parseFloat(item.quantity) * parseFloat(item.service?.price)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="3" className="theadtd" style={styles.theadtd}>Package Price</td>
                                        <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.package_price}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="theadtd" style={styles.theadtd}>Subtotal</td>
                                        <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.sub_total}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="theadtd" style={styles.theadtd}>Tax</td>
                                        <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.billing_tax}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="theadtd" style={styles.theadtd}>Discount ({data.discount_symbol})</td>
                                        <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                                            {data.discounted_amount}
                                        </td>
                                    </tr>
                                    {data?.payment_modes?.map((payment, index) => (
                                        <tr key={index}>
                                            <td colSpan="3" className="theadtd" style={styles.theadtd}>
                                                {paymentOption(payment.payment_mode)}
                                                {payment.deleted_at ? " (Old Payment)" : ""}
                                            </td>
                                            <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                                                {payment.price}
                                            </td>
                                        </tr>
                                    ))}

                                    <tr>
                                        <td colSpan="3" className="theadtd" style={styles.theadtd}>Grand Total</td>
                                        <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.round_off_grand_total}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
};

export default AdvancePackageInvoice;
